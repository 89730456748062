import {
  renderFormParams,
  ApiError,
  getKeycloakExchangeFormBody,
} from "./serviceUtil";

/**
 * Base class for all sorts of HTTP services, currently servicing the User API and the Auth API
 */
export class HttpService {
  constructor(keycloak, cfg) {
    this.keycloak = keycloak;
    this.cfg = cfg;
    this.pendingTokenExchange = false;
    this.pendingRequests = [];
  }

  async getAuthzsvcApiToken() {
    // Do we already have a token?
    let expiry = Date.parse(sessionStorage.getItem(`${this.tokenName}Timeout`));

    if (!expiry || expiry < Date.now()) {
      const formBody = getKeycloakExchangeFormBody(
        this.keycloak,
        this.cfg,
        this.audience
      );

      try {
        this.blockOtherTokenExchangeRequests();

        const keycloakResponse = await fetch(
          this.keycloak.authServerUrl + this.cfg.tokenExchangeEndpoint,
          {
            method: "POST",
            credentials: "include", // make sure keycloak cookies are included in the request
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formBody,
          }
        );

        const jsonResponse = await keycloakResponse.json();

        if (!keycloakResponse.ok) {
          throw new Error(keycloakResponse.statusText);
        }

        expiry = new Date();
        expiry.setTime(expiry.getTime() + jsonResponse.expires_in * 900);

        sessionStorage.setItem(this.tokenName, jsonResponse.access_token);
        sessionStorage.setItem(`${this.tokenName}Timeout`, expiry);

        this.retryPendingRequests();

        return jsonResponse.access_token;
      } catch (error) {
        console.error(`Failed to exchange token =\n`, error.message);
        throw error;
      }
    } else {
      return sessionStorage.getItem(this.tokenName);
    }
  }

  async getAuthHeaders(defaultHeaders) {
    let headers = {};
    try {
      const token = await this.getAuthzsvcApiToken();
      headers = {
        Authorization: `Bearer ${token}`,
      };
      if (defaultHeaders) {
        headers["Content-Type"] = `application/json; charset=utf-8`;
      }
    } catch (err) {
      console.error("Error obtaining auth api token: " + err);
    }

    return headers;
  }

  /**
   * Sends a request to the server
   * @param {string} url
   * @param {string} method
   * @param {object} data
   * @param {boolean} defaultHeaders
   */
  async sendRequest(url, method, data = null, defaultHeaders = true) {
    if (this.pendingTokenExchange) {
      // Some requests may be triggered concurrently, leading to errors as each
      // of them tries to perform token exchange to authorize with the authz-api.
      // To address this issue, synchronization is required. Only the first request
      // should attempt token exchange, and the others should be retried once the
      // token exchange is completed.
      return new Promise((resolve) => {
        this.queuePendingRequest(resolve);
      }).then(() => {
        return this.sendRequest(url, method, data);
      });
    }

    const errorTitle = `We're sorry! An error has occurred`;
    const fetchBody = {
      method: method,
      headers: await this.getAuthHeaders(defaultHeaders),
    };

    if (data !== null) {
      fetchBody.body = data instanceof FormData ? data : JSON.stringify(data);
    }

    const fetchResponse = await fetch(url, fetchBody);
    if (fetchResponse.ok) {
      if (fetchResponse.status === 204) {
        return { status: 204 };
      }
      const contentType = fetchResponse.headers.get("Content-Type");

      // Check if the response is of type application/octet-stream and read as a Blob
      if (contentType && contentType.includes("application/octet-stream")) {
        const blob = await fetchResponse.blob();
        return blob;
      }
      return fetchResponse;
    } else {
      let resp = await fetchResponse.statusText;
      try {
        resp = await fetchResponse.json();
      } catch (err) {
        console.error(resp);
      }

      /* Using statusText instead since if it's e.g. a 404, the .json() explodes */
      throw new ApiError(errorTitle, resp);
    }
  }

  /**
   * Builds a query using the query params passed
   * @param {string}
   * @param {object} queryParams should be of the type {"limit": ..., "filter": ..., "field", "sort"}
   */
  async get(url, queryParams) {
    let query = "";
    if (queryParams && Object.keys(queryParams).length > 0) {
      query = "?" + renderFormParams(queryParams);
    }
    return this.sendRequest(`${url}${query}`, "GET");
  }

  /**
   * Send a put request with or without data for a server
   * @param {*} url
   * @param {*} data
   */
  async put(url, data = null) {
    return this.sendRequest(url, "PUT", data);
  }

  blockOtherTokenExchangeRequests() {
    this.pendingTokenExchange = true;
  }

  queuePendingRequest(retryRequest) {
    this.pendingRequests.push(retryRequest);
  }

  retryPendingRequests() {
    this.pendingTokenExchange = false;
    this.pendingRequests.forEach((retryRequest) => retryRequest());
    this.pendingRequests = [];
  }
}
